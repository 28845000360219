.page-editor-page {
  .page-editor-page__editor {
    display: flex;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }

    .page-editor-page__editor-side {
      width: 50%;
      padding: 4px;

      @media screen and (max-width: 640px) {
        width: 100%;
        padding: 0;
      }

      .page-editor-page__editor-code {
        height: calc(100vh - 40px);

        .page-editor-page__editor-code-widget {
          height: 100%;
        }
      }
      .page-editor-page__editor-preview {
        border: black 2px solid;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        height: calc(100vh - 40px);
      }
    }
  }
}